












import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
    data() {
        const that = this;
        return {
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                },
                content: {
                    name: "Companions",
                    urlEnabled: true,
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            name: {
                                attrs: {
                                    label: "Name",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                        },
                    },
                    displayFields: {
                        name: {
                            text: "Name",
                        },
                        description: {
                            text: "Description",
                        },
                        image: {
                            text: "Image",
                        },
                        createdTime: {
                            text: "Created Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        updatedTime: {
                            text: "Updated Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        action: {
                            text: "Actions",
                        },
                    },
                    topActionButtons: {
                        insert: userManager.checkRole(["systemAdmin", "admin"]) && {},
                    },
                    template: {
                        itemActionButtons: {
                            edit: userManager.checkRole(["systemAdmin", "admin"]) && {
                                target: {
                                    dialog: {
                                        ext: {
                                            subTitleMaker: "name",
                                        },
                                    },
                                },
                            },
                            delete: userManager.checkRole(["systemAdmin", "admin"]) && {},
                        },
                    },
                    findAllOptionsFilter(options) {
                        return options;
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call(
                                "companions",
                                "findAll",
                                options
                            );
                            return [items, count];
                        },
                        async insert(item) {
                            const fileBase64 = await that.createImage(item.image);
                            return await coreApiClient.call(
                                "companions",
                                "create",
                                undefined,
                                { ...item, imageBase64: fileBase64 }
                            );
                        },
                        async update(item) {
                            const fileBase64 = await that.createImage(item.image);
                            return await coreApiClient.call(
                                "companions",
                                "update",
                                {
                                    id: item._id,
                                },
                                {
                                    data: { ...item, imageBase64: fileBase64 },
                                }
                            );
                        },
                        async delete(item) {
                            return await coreApiClient.call("companions", "delete", {
                                id: item._id,
                            });
                        },
                    },
                    default: {
                        defaultInputs: {
                            name: {
                                attrs: {
                                    label: "Name",
                                    required: true,
                                },
                            },
                            description: {
                                type: "textLarge",
                                attrs: {
                                    label: "Description",
                                },
                            },
                            image: {
                                type: "file",
                                attrs: {
                                    label: "Image",
                                    accept: "image/*"
                                },
                            },
                        },
                        insertForm: userManager.checkRole(["systemAdmin", "admin"]) && {
                            content: {
                                fieldNames: ["name", "description", "image"],
                            },
                        },
                        editForm: userManager.checkRole(["systemAdmin", "admin"]) && {
                            content: {
                                fieldNames: ["name", "description", "image"],
                            },
                        },
                        delete: {
                            confirmDisplayField: "name",
                        },
                    },
                },
            },
        };
    },
    methods: {
        createImage(file) {
            return new Promise((resolve, reject) => {
                if (file && typeof file !== "string") {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(null);
                }
            });
        },
    },
});

